import type { Locale } from "date-fns";
import { da, enUS } from "date-fns/locale";

export const SHORT_DATE_FORMAT = "P";
export const FULL_DATE_FORMAT = "PP p";
export const DATE_FORMAT = "PP";
export const INPUT_DATE_FORMAT = "yyyy-MM-dd";
export const INPUT_DATETIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const DISPLAY_DATE_TIME_FORMAT = "Pp";
export const DISPLAY_TIME_FORMAT = "p";
export const DISPLAY_FULL_DATE_TIME_FORMAT = "PPP p";
export const DISPLAY_SHORT_DATE_MONTH_FORMAT = "do LLL";
export const DATE_YEAR_FORMAT = "yyyy";
export const DATE_MONTH_YEAR_FORMAT = "MMM yyyy";

export const DEFAULT_PHONE_PREFIX = "+45";

export const LOCALE_STORAGE_KEY = "lang";

export const DATE_LOCALES: Record<string, Locale> = { en: enUS, da };

export const DEFAULT_PRIMARY_HSL = "214 100% 15%";
